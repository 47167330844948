<template>
    <!-- 内容 -->
    <div class="index-new">
        <div class="new-content">
            <div class="new-title">
                展览中心
            </div>
            <!-- <div class="new-title">
                <div class="new-nav" v-for="(item, index) in navList" :key="index"
                :class="index == navIndex ? 'new-nav-active' : ''" @click="changIndex(index)">{{item}}</div>
                <div class="new-title-line" :style="{left: left+'px'}"></div>
            </div> -->
            <div class="new-list">
                <div class="new-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                    <img :src="item.image"/>
                    <div class="new-item-info">
                        <div class="new-item-info-title">{{ item.title }}</div>
                        <p>{{ item.describe }}</p>
                    </div>
                    <div class="new-item-time">
                        <span>{{ item.releasetime_text2[1] }}</span>
                        <div class="new-tim-line"></div>
                        <div class="new-time-year">{{ item.releasetime_text2[0] }}</div>
                    </div>
                </div>
            </div>
            <!-- 分页 -->
            <paging @changeIndex="changeIndex" :allNum="total"></paging>
        </div>
        
    </div>
</template>
<script>
import paging from '@/components/paging'
export default {
    name: 'exhibition',
    components:{
        paging
    },
    data() {
        return {
            navList: ['成人书画展', '少儿绘画展'],
            navIndex: 0,
            left: 395,
            list:[],
            page:1,//页
            limit:6,//条
            total:0,//总页数
            count:0,//总条数
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/exhibition',
                method:'POST',
                data:{
                    page:this.page,
                    limit:this.limit
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'展览中心')
                    this.list=res.data.list
                    this.count=res.data.count
                    this.total=res.data.total
                }
            })
        },
        navto(item){
            this.$router.push({
                path:"/news/newDetail",
                query:{
                    id:item.id
                }
            })
        },
        changIndex(index) {
            this.navIndex = index
            this.left = 395+275*index
            this.getList()
        },
        changeIndex(e){
            console.log(e) 
            this.page=e
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
    .index-new{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #F6F6EA;
    }
    // 内容
    .new-content{
        width: 1200px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        padding: 60px 40px;
    }
    .new-title{
        padding-bottom: 12px;
        margin-bottom: 50px;
        font-weight: 600;
        color: #333333;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .new-nav{
        color: #272727;
        font-size: 30px;
        font-weight: 400;
        padding: 0 60px;
        cursor: pointer;
    }
    .new-nav-active{
        color: #207168;
        font-weight: 500;
    }
    .new-title-line{
        width: 67px;
        height: 4px;
        background: #207168;
        position: absolute;
        transition: all 1s;
        bottom: 0;
        border-radius: 2px;
    }
    .new-nav:last-child{
        padding-left: 60px;
    }
    .new-list{
        display: flex;
        flex-direction: column;
        
    }
    .new-item{
        display: flex;
        margin-bottom: 30px;
        cursor: pointer;
        padding-bottom: 30px;
        border-bottom: 1px solid #ccc;
    }
    .new-item-info{
        flex: 1;
        padding: 0 44px;
        display: flex;
        flex-direction: column;
    }
    .new-item-info-title{
        color: #313131;
        font-size: 20px;
        padding-bottom: 30px;
    }
    .new-item-info p{
        color: #7D7D7D;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-indent: 2em;
    }
    .new-item-info:hover p{
        color: #26716C;
    }
    .new-item img{
        width: 160px;
        height: 160px;
    }
    .new-item-time{
        flex-shrink: 0;
        padding: 24px 0 24px 35px;
        border-left: 1px dashed #707070;
        color: #313131;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .new-tim-line{
        margin: 13px 0 6px;
        width: 60px;
        height: 0;
        border: 1px solid #C1C1C1;
    }
    .new-time-year{
        color: #707070;
        font-size: 16px;
        font-weight: 400;
    }
</style>